<template>
	<v-layout>
		<v-menu offset-y right :close-on-content-click="false">
			<template v-slot:activator="{on: menu}">
				<v-tooltip bottom>
					<template v-slot:activator="{on: tooltip}">
						<v-btn icon v-on="{...menu, ...tooltip}">
							<v-icon color="primary">sort</v-icon>
						</v-btn>
					</template>
					<span>{{$t('documents.sort.menu')}}</span>
				</v-tooltip>
			</template>
			<v-list>
				<v-list-tile @click="sortByAlpha">
					<v-list-tile-action>
						<SortButton
							ref="alpha"
							:value="sorting"
							:field="'name'"
							:icon="'sort_by_alpha'"
							:tooltip-asc="$t('documents.sort.name.asc')"
							:tooltip-desc="$t('documents.sort.name.desc')"
							@sorted="updateSortState"
						/>
		
					</v-list-tile-action>
					<v-list-tile-title>{{$t('documents.sort.by-name')}}</v-list-tile-title>
				</v-list-tile>
				<v-list-tile @click="sortByChrono">
					<v-list-tile-action>
						<SortButton
							ref="chrono"
							:value="sorting"
							:icon="'schedule'"
							:field="'start_date'"
							:tooltip-asc="$t('documents.sort.chrono.asc')"
							:tooltip-desc="$t('documents.sort.chrono.desc')"
							@sorted="updateSortState"
						/>
					</v-list-tile-action>
					<v-list-tile-title>{{$t('documents.sort.by-chrono')}}</v-list-tile-title>
				</v-list-tile>
			</v-list>
		</v-menu>
	</v-layout>
</template>

<script>

import { mapState, mapActions } from "vuex"

export default {
	name: 'DocumentsToolbarSorting',
	components: {
		SortButton: () => ({
			component: import('@/components/Commons/SortButton')
		})
	},
	computed: {
		...mapState({
			sorting: state => state.documents.sort
		}),
	},
	methods: {
		...mapActions('documents', ['setSort']),
		sortByChrono () {
			this.$refs.chrono.sort()
		},
		sortByAlpha () {
			this.$refs.alpha.sort()
		},
		updateSortState (res) {
			this.setSort(res)
		}
	}
}
</script>
